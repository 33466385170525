import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import Splashscreen from './components/progress/Splashscreen'
import './i18n'
import './index.css'
import Error from './pages/error/Error'
import reportWebVitals from './reportWebVitals'
import { initialize } from './services/AppInsights'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const { reactPlugin } = initialize()
const App = lazy(() => import('./App'))

root.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <Error />} appInsights={reactPlugin}>
        <Suspense fallback={<Splashscreen />}>
          <App />
        </Suspense>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
