import Backdrop from "@mui/material/Backdrop";
import ErrorAlert from "../../components/dialog/error/ErrorAlert";
import Splashscreen from "../../components/progress/Splashscreen";

export default function Error() {
    return (
        <>
            <Splashscreen />
            <Backdrop open></Backdrop>
            <ErrorAlert />
        </>
    )
}